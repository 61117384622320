import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, decodeJWT } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, company } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      company,
    };
  },
  LOGIN: (state, action) => {
    const { user , company } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      company,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    company: null,
  }),
  REGISTER: (state, action) => {
    const { user , company } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      company,
    };
  },
  SET_SELECTED_COMPANY: (state, action) => {
    const { company } = action.payload;
    return {
      ...state,
      company:{
        company_id: company?.company_id,
        name: company?.name,
        short_name: company?.short_name,
        level: company?.level,
      },
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setSelectedCompany: () => Promise.resolve(), 
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};


function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        
        if (accessToken && isValidToken(accessToken)) {
          const user = decodeJWT(accessToken);
          const company  = JSON.parse(localStorage.getItem('company'));
          // console.log(user);
          setSession(accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              company,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              company: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            company: null,
          },
        });
      }
    };
    
    initialize();
  }, []);

  const login = async ({username, password , recaptchaToken , loginType , userType, urlEndpoint = "/auth/login_user"}) => {
    try {
      // console.log(username , password);
      const response = await axios.post(urlEndpoint, {
        username,
        password,
        loginType ,
        userType,
        recaptchaToken,
      }).then( resp => { let returned; if( resp?.data ){returned = resp.data; }else{ console.log(resp); }; return returned;  }  );
      console.log(response);
      const {accessToken , company } = response;
      if(accessToken){
        const user = decodeJWT(accessToken);
        setSession(accessToken);
        localStorage.setItem('company', JSON.stringify(company));
        
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            company,
          },
        });
      }else{
        setSession(null);
        console.log(response);
        throw "Invalid ID / Password"
      }
      
    } catch (error) {
      setSession(null);
      throw {message:error.toString()}
    }
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/auth/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const changeCompany = async ({company_id , company_code}) => {
    try {
      const response = await axios.post("/company/change_company", {
        company_id,
        company_code,
      }).then( resp => { let returned; if( resp?.data ){returned = resp.data; }else{ console.log(resp); }; return returned;  }  );
      console.log(response);
      const {accessToken , company } = response;
      if(accessToken){
        const user = decodeJWT(accessToken);
        setSession(accessToken);
        localStorage.setItem('company', JSON.stringify(company));
        dispatch({
          type: 'SET_SELECTED_COMPANY',
          payload: {
            company,
            user,
          },
        });
      }else{
        setSession(null);
        console.log(response);
      }
    } catch (error) {
      setSession(null);
      throw {message:error.toString()}
    }
  };

  // const setSelectedCompany = async (company) => {
  //   try {
  //     const response = await axios.post("/company/change_company", {
  //       username,
  //       password,
  //       recaptchaToken,
  //     }).then( resp => { let returned; if( resp?.data ){returned = resp.data; }else{ console.log(resp); }; return returned;  }  );
  //     localStorage.setItem('company', JSON.stringify(company));
  //     dispatch({
  //       type: 'SET_SELECTED_COMPANY',
  //       payload: {
  //         company,
  //       },
  //     });
  //   } catch (error) {
  //     setSession(null);
  //     throw {message:error.toString()}
  //   }
  // };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        changeCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider , decodeJWT};
