import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';

const slice = createSlice({
  name: 'inventory_report', // This is the name of the slice
  initialState: {
    hasError: null,
    isLoading: false,
    isLoadingDetail: false,
    stockRecap: {},
    data: {},
  },
  reducers: {
    noError(state) { // NO ERROR
      state.hasError = false;
    },
    unsetError(state) { // UNSET ERROR
      state.hasError = null;
    },
    hasError(state, action) { // HAS ERROR
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startLoadingDetail: (state, action) => {
      state.isLoadingDetail = true;
    },
    setStockRecapData: (state, action) => {
      state.isLoading = false;
      state.stockRecap = action.payload;
    },
    setData: (state, action) => {
      state.isLoadingDetail = false;
      state.data = action.payload;
    },
  },
});

export const { 
    noError , 
    unsetError , 
    hasError, 
    startLoading , 
    startLoadingDetail , 
    setDatas , 
    setData ,
} = slice.actions;

const mainEndpoint = "/inventory_report"

export function stockRecap({ formData = {} , params = {} , endpoint = "stockRecap" } = {data: {} , params: {} , endpoint: "stockRecap" }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let endpointURL = `${mainEndpoint}/${endpoint}`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , formData )?.then( resp => resp?.data);
      // console.log("stockRecap", response);
      if(response?.success && response?.data){
        dispatch(slice.actions.setStockRecapData(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}



export default slice.reducer;
