import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';
import { getFirstAndLastDateOfWeek } from 'utils/util'; 

const [start_date , end_date] = getFirstAndLastDateOfWeek();

const slice = createSlice({
  name: 'sales_report', // This is the name of the slice
  initialState: {
    hasError: null,
    isLoading: false,
    isLoadingDetail: false,
    isOpenDrawerDetailSales: false,
    isOpenDrawerDetailExpense: false,
    isOpenDrawerDetailIncome: false,
    start_date: start_date, 
    end_date: end_date,
    dailyBankRecap: {},
    dailySales: {},
    data: {},
  },
  reducers: {
    noError(state) { // NO ERROR
      state.hasError = false;
    },
    unsetError(state) { // UNSET ERROR
      state.hasError = null;
    },
    hasError(state, action) { // HAS ERROR
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startLoadingDetail: (state, action) => {
      state.isLoadingDetail = true;
    },
    setDrawerDetailSales: (state, action) => {
      state.isOpenDrawerDetailSales = !(state.isOpenDrawerDetailSales);
    },
    setDrawerDetailExpense: (state, action) => {
      state.isOpenDrawerDetailExpense = !(state.isOpenDrawerDetailExpense);
    },
    setDrawerDetailIncome: (state, action) => {
      state.isOpenDrawerDetailIncome = !(state.isOpenDrawerDetailIncome);
    },
    setStartDate: (state, action) => {
      state.isLoading = false;
      state.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.isLoading = false;
      state.end_date = action.payload;
    },
    setDailyBankRecapData: (state, action) => {
      state.isLoading = false;
      state.dailyBankRecap = action.payload;
    },
    setDailySalesData: (state, action) => {
      state.isLoading = false;
      state.dailySales = action.payload;
    },
    setData: (state, action) => {
      state.isLoadingDetail = false;
      state.data = action.payload;
    },
  },
});

export const { 
    noError , 
    unsetError , 
    hasError, 
    startLoading , 
    startLoadingDetail ,
    setDrawerDetailSales, 
    setDrawerDetailExpense,
    setDrawerDetailIncome,
    setStartDate ,
    setEndDate ,
    setDatas , 
    setData ,
} = slice.actions;

const mainEndpoint = "/sales_report"

export function dailyBankRecap({ formData = {} , params = {} , endpoint = "dailyBankRecap" } = {formData: {} , params: {} , endpoint: "dailyBankRecap" }) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const state = getState();
      if(!formData?.start_date){ formData.start_date = state.sales_report.start_date} ;
      if(!formData?.end_date){ formData.end_date = state.sales_report.end_date} ;

      let endpointURL = `${mainEndpoint}/${endpoint}`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , formData )?.then( resp => resp?.data);
      // console.log("dailyBankRecap", response);
      if(response?.success && response?.data){
        dispatch(slice.actions.setDailyBankRecapData(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function dailyReport({ formData = {} , params = {} , endpoint = "dailyReport" } = {formData: {} , params: {} , endpoint: "dailyReport" }) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const state = getState();
      if(!formData?.start_date){ formData.start_date = state.sales.start_date} ;
      if(!formData?.end_date){ formData.end_date = state.sales.end_date} ;

      let endpointURL = `${mainEndpoint}/${endpoint}`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , formData )?.then( resp => resp?.data);
      // console.log("dailyReport", response);
      if(response?.success && response?.data){
        dispatch(slice.actions.setDailySalesData(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}


export default slice.reducer;
